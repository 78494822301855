import React from 'react';
import styled from 'styled-components';

const Rule: React.FC = () => {
  return <StyledRule />;
};

const StyledRule = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  padding: 0;
`;

export default Rule;
