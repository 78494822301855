import { useStaticQuery, graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';

export interface SolutionsData {
  edges: {
    node: {
      frontmatter: {
        slug: string;
        display: string;
        hidden: boolean;
        featureImg: {
          childImageSharp: {
            fixed: FixedObject;
          };
        };
      };
    };
  }[];
}

export const useSolutions = () => {
  return useStaticQuery(graphql`
    query SolutionsHome {
      industry: allMdx(
        filter: { frontmatter: { type: { eq: "solution" }, subtype: { eq: "industry" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              slug
              display
              hidden
              featureImg {
                childImageSharp {
                  fixed(quality: 100, width: 1500) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
      useCases: allMdx(
        filter: { frontmatter: { type: { eq: "solution" }, subtype: { eq: "use case" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              slug
              display
              hidden
              featureImg {
                childImageSharp {
                  fixed(quality: 100, width: 1500) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
};
