import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled, { css, useTheme } from 'styled-components'
import { uid } from 'react-uid'
import Img, { FluidObject } from 'gatsby-image'
import Container from '../Container'
import Rule from '../Rule'

import MichaWotton from './MichaWotton'
import DavidFussell from './DavidFussell'
import RichardKimberCeo from './RichardKimberCeo'
import KyleBowness from './KyleBowness'
import { lighten } from 'polished'

const people = [RichardKimberCeo, MichaWotton, KyleBowness, DavidFussell]

//  creates path to page from their name e.g /our-people/richard-kimber
const toKebabCase = (str: string) => {
  let url
  if (str && str !== null && str !== undefined) {
    const regex = new RegExp(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    url = str
      .match(regex)!
      .map((x: string) => x.toLowerCase())
      .join('-')
  }
  return url
}

const OurPeople: React.FC = () => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query People {
      richard: file(relativePath: { eq: "RichardKimber/assets/RichardKimber.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      micha: file(relativePath: { eq: "MichaWotton/assets/MichaWotton.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      david: file(relativePath: { eq: "DavidFussell/assets/DavidFussell.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kyle: file(relativePath: { eq: "KyleBowness/assets/KyleBowness.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledOurValues id='our-people'>
      <Container>
        <SectionHeading>Our Leadership Team</SectionHeading>
        <Rule />
        <Wrapper>
          {Object.keys(data).length === people.length &&
            people.map((el, index) => {
              return (
                <Link key={uid(el)} to={`/our-people/${toKebabCase(el.name)}/`}>
                  <Person index={index}>
                    <StyledImg
                      fluid={data[el.id].childImageSharp.fluid}
                      style={{ overflow: 'visible' }}
                      alt={`${el.name} - ${el.role}`}
                      imgStyle={{
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        borderRadius: '50%',
                        boxShadow: theme.boxShadow.large,
                      }}
                    />
                    <Name>{el.name}</Name>
                    {Array.isArray(el.department) ? <Role>{el.department[0]}</Role> : <Role>{el.department}</Role>}
                  </Person>
                </Link>
              )
            })}
        </Wrapper>
      </Container>
    </StyledOurValues>
  )
}

export const hover = (index: number) => {
  return index % 2 === 0
    ? css`
        &:before {
          top: -0.5rem;
          left: -1rem;
        }
      `
    : css`
        &:before {
          top: -0.5rem;
          left: 1rem;
        }
      `
}

export const StyledImg = styled(Img)<{ fluid: FluidObject }>`
  --size: 12rem;
  max-width: 100%;
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
  border: 1px solid #dde2e5;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.purple};
    transition: all 0.5s ease;
  }

  @media all and (max-width: 26.563em) {
    --size: 6rem;
  }
`

const StyledOurValues = styled.section`
  padding: 3rem 0 3rem;
  margin-top: -1px;
  position: relative;

  @media all and (max-width: 26.563em) {
    padding: 0;
  }
`

export const SectionHeading = styled.h3`
  font-size: 4rem;
  font-weight: 300;
  letter-spacing: -2px;
  padding-bottom: 3rem;
  color: ${({ theme }) => theme.colors.grey400};
  line-height: 1;
  @media all and (max-width: 36.625rem) {
    font-size: 2.25rem;
    padding-bottom: 1.25rem;
    line-height: 1.2;
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 3rem 0;

  a:last-child:nth-child(3n - 2) {
    grid-column-start: 2;
  }

  @media all and (max-width: 48em) {
    grid-template-columns: repeat(2, 1fr);
    gap: 7rem;

    a:last-child:nth-child(3n - 2) {
      grid-column-start: 1;
    }
  }

  @media all and (max-width: 36.625em) {
    gap: 4rem 1rem;
  }

  @media all and (max-width: 26.563em) {
    gap: 3rem 1rem;
    justify-content: end;
  }
`

export const Role = styled.span`
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => lighten(0.4, theme.colors.purple)};
  font-size: 0.875rem;
  letter-spacing: 1px;
  border: 1px solid ${({ theme }) => lighten(0.4, theme.colors.purple)};
  width: fit-content;
  padding: 4px 16px;
  border-radius: 30px;
  user-select: none;
  margin-left: -4px;
  white-space: nowrap;
  text-align: center;
  margin: 0 auto;

  @media all and (max-width: 880px) {
    font-size: 0.75rem;
  }
  @media all and (max-width: 36.625em) {
    font-size: 0.5rem;
  }
`

export const Person = styled.div<{ index: number }>`
  cursor: pointer;
  transition: filter 0.25s ease;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    ${Role} {
      border: 1px solid ${({ theme }) => theme.colors.purple};
      color: ${({ theme }) => theme.colors.purple};
    }

    ${StyledImg} {
      &:hover {
        ${({ index }) => hover(index)}
      }
    }
  }
`

export const Name = styled.h4`
  font-size: 1.25rem;
  padding: 1rem 0;
  font-weight: 500;
  text-align: center;

  @media all and (max-width: 36.625em) {
    font-size: 1rem;
  }
`

export default OurPeople