import React from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import Footer from '../components/Footer/Footer'
import ROICalculator from '../components/ROICalculator'
import Img from 'gatsby-image'

import { Main } from '../components/Styled'
import { Link, PageProps } from 'gatsby'
import { SectionHeading } from '../components/OurPeople'
import { Site } from '../components/Site'
import { SEO } from '../SEO'
import { useSolutions, SolutionsData } from '../hooks/useSolutions'

export default (props: PageProps) => {
  const data = useSolutions()
  const { edges: industries }: SolutionsData = data.industry
  const { edges: useCases }: SolutionsData = data.useCases

  return (
    <Site seo={SEO.solutions} {...props}>
      <Main>
        <Solutions>
          <Container>
            <SectionHeading>Discover a solution</SectionHeading>
            <Banner text='By Use Cases' />
          </Container>
          <SolutionsGrid>
            {useCases
              .filter(x => x.node.frontmatter.hidden !== true)
              .map(({ node }, i) => {
                const { slug, featureImg, display } = node.frontmatter
                const text = display.split('\\n').map(val => <P key={val}>{val}</P>)
                return (
                  <Link key={slug} to={slug + '/'}>
                    <Item index={i}>
                      <TextInner>{text}</TextInner>
                      <ImgWrapper>
                        {featureImg && (
                          <Img fixed={featureImg.childImageSharp.fixed} style={{ width: '100%', height: '100%' }} />
                        )}
                      </ImgWrapper>
                    </Item>
                  </Link>
                )
              })}
          </SolutionsGrid>
          <Container>
            <Banner text='By Industry' />
          </Container>
          <SolutionsGrid>
            {industries
              .filter(x => x.node.frontmatter.hidden !== true)
              .map(({ node }, i) => {
                const { slug, featureImg, display } = node.frontmatter
                const text = display.split('\\n').map(val => <P key={val}>{val}</P>)
                return (
                  <Link key={slug} to={slug + '/'}>
                    <Item index={i}>
                      <TextInner>{text}</TextInner>
                      <ImgWrapper>
                        {featureImg && (
                          <Img fixed={featureImg.childImageSharp.fixed} style={{ width: '100%', height: '100%' }} />
                        )}
                      </ImgWrapper>
                    </Item>
                  </Link>
                )
              })}
          </SolutionsGrid>
        </Solutions>

        <RoiWrapper>
          <ROICalculator />
        </RoiWrapper>
      </Main>
      <Footer />
    </Site>
  )
}

const Banner = ({ text }: { text: string }) => (
  <StyledBanner>
    <H4>
      <span>{text}</span>
    </H4>
  </StyledBanner>
)

const RoiWrapper = styled.div`
  padding: 3rem 0;
`

const StyledBanner = styled.header`
  padding: 1.5rem 0;

  h4 {
    position: relative;

    span {
      background: #fff;
      position: relative;
      z-index: 5;
      padding: 0 8px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.colors.grey300};
      left: 0;
      top: 50%;
    }
  }
`

const P = styled.p`
  text-transform: capitalize;
  font-weight: 500;
`

const Solutions = styled.div`
  padding: 9rem 0;
  transition: all 0.75s ease;
  position: relative;
`

const SolutionsGrid = styled.article`
  display: flex;
  transition: all 0.5s ease-in-out;

  @media all and (max-width: 48em) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  a {
    transition: all 0.4s ease-in-out;
    flex-basis: 50%;
    text-align: center;
    line-height: 1.2;
  }

  & a:hover {
    flex-basis: 65%;
  }
`

const TextInner = styled.div`
  position: relative;
  z-index: 5;
  padding: 0 1.5rem 1.5rem;
`

interface ItemProps {
  index: number
}

const Item = styled.div<ItemProps>`
  min-height: 50vh;
  color: white;
  font-size: 2rem;
  display: flex;
  align-items: start;
  transition: all 0.5s ease-in-out;
  justify-content: flex-end;
  line-height: 1.2;
  flex-direction: column;
  position: relative;
  background: #ccc;
  overflow: hidden;
  text-align: start;
`

const ImgWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const H4 = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 1.25rem;
  padding: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: ${({ theme }) => theme.colors.purple};
`
